import { graphql } from 'gatsby'
import React, { FC } from 'react'

import {
  ScienceTeamMemberPage,
  ScienceTeamMemberPageProps,
} from '../components/team/ScienceTeamMemberPage'
import { withIsDispensaryPageContext } from '../hoc/withIsDispensaryPageContext'

export type ScienceTeamMemberTemplateProps = ScienceTeamMemberPageProps

const ScienceTeamMemberTemplate: FC<ScienceTeamMemberTemplateProps> = ({ data, pageContext }) => {
  return <ScienceTeamMemberPage data={data} pageContext={pageContext} />
}

// Query for science team member data
export const query = graphql`
  query ScienceTeamMemberQuery($id: String!) {
    teamMember: sanityTeamMemberScience(id: { eq: $id }) {
      ...sanityScienceTeamMember
    }
  }
`

export default withIsDispensaryPageContext(ScienceTeamMemberTemplate)
