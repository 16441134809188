import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  TeamMemberButtonNavigation,
} from '@dfh-corp-sites/ui'
import { BlockContentProps } from '@sanity/block-content-to-react'
import { graphql, PageProps } from 'gatsby'
import React, { FC, useEffect, useState } from 'react'

import Layout from '../../components/global/Layout'
import Link from '../../components/global/Link'
import { SanityImage } from '../../graphql/gatsby'
import Head from '../../layouts/Head'
import { TeamMemberContext } from '../../lib/pages/types'
import { getGatsbyImageDataForAsset } from '../../utils/sanity/image'
import BodyPortableText from '../sanity-page/BodyPortableText'

type ScienceTeamMemberQueryData = {
  teamMember: {
    id: string
    name: string
    title?: string
    degrees?: string
    teamType?: 'coreTeam' | 'advisoryBoard'
    biography?: BlockContentProps['blocks']
    photo?: SanityImage
    slug?: {
      current: string
    }
  }
}

export type ScienceTeamMemberPageProps = Pick<
  PageProps<ScienceTeamMemberQueryData, TeamMemberContext>,
  'data' | 'pageContext'
>

export const ScienceTeamMemberPage: FC<ScienceTeamMemberPageProps> = ({ data, pageContext }) => {
  const { teamMember } = data
  const { prevName, prevTitle, prevSlug, nextName, nextTitle, nextSlug } = pageContext

  const [isClient, setIsClient] = useState(false)
  const [headerHeight, setHeaderHeight] = useState(0)

  const image = teamMember.photo
    ? getGatsbyImageDataForAsset(teamMember.photo, {
        placeholder: 'blurred',
      })
    : null

  const title = `${teamMember.name}${teamMember.degrees ? `, ${teamMember.degrees}` : ''} - ${
    teamMember.title || 'Science Team'
  } | Designs for Health`

  const description = teamMember.biography
    ? `Learn more about ${teamMember.name}${teamMember.degrees ? `, ${teamMember.degrees}` : ''}, ${
        teamMember.title || 'Science Team'
      } at Designs for Health.`
    : 'Our science team at Designs for Health is committed to providing high-quality nutritional products backed by scientific research.'

  useEffect(() => {
    setIsClient(true)

    const bioHeader = document.getElementById('bio-header')
    if (bioHeader) {
      setHeaderHeight(bioHeader.offsetHeight)
    }

    const handleResize = () => {
      const _bioHeader = document.getElementById('bio-header')
      if (_bioHeader) {
        setHeaderHeight(_bioHeader.offsetHeight)
      }
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const imageStyle = isClient && headerHeight > 0 ? { marginTop: `-${headerHeight}px` } : {}

  return (
    <>
      <Head title={title} description={description} image={teamMember.photo} />
      <Layout>
        <main id="main-content" tabIndex={-1} className="outline-none grid">
          <div className="w-full border-b border-b-brand-rule-grey grid md:block overflow-clip h-full">
            <div className="w-full">
              <div
                id="bio-header"
                title={`${teamMember.name}${teamMember.degrees ? `, ${teamMember.degrees}` : ''} - ${
                  teamMember.title || ''
                }`}
                className="flex flex-col gap-3 md:gap-3.5 lg:pl-10 xl:pb-10 lg:pt-6 border-b border-b-brand-rule-grey px-2.5 pb-[30px] lg:h-auto pt-5 md:pl-5 md:w-1/2 w-full"
              >
                <nav aria-label="Breadcrumb">
                  <Breadcrumb className="[&_li]:text-base">
                    <BreadcrumbList>
                      <BreadcrumbItem>
                        <BreadcrumbLink asChild>
                          <Link to={'/our-story/science-team'}>Science Team</Link>
                        </BreadcrumbLink>
                      </BreadcrumbItem>
                      <BreadcrumbSeparator />
                      <BreadcrumbItem>
                        <BreadcrumbPage>Our Science Team</BreadcrumbPage>
                      </BreadcrumbItem>
                    </BreadcrumbList>
                  </Breadcrumb>
                </nav>
                <h1
                  id="team-member-heading"
                  className="xl:text-6xl md:text-[50px] text-[40px] text-brand-navy font-semibold max-w-96 leading-none text-wrap"
                >
                  {teamMember.name}
                </h1>
                <h2 className="md:text-[40px] text-xl text-black md:inline-block text-wrap">
                  {teamMember.degrees}
                </h2>
                {teamMember.title && (
                  <h3 className="md:text-2xl text-xl text-brand-navy-2 text-wrap">
                    {teamMember.title}
                  </h3>
                )}
              </div>

              <div
                className="w-1/2 float-right relative hidden md:block ml-5"
                aria-hidden="true"
                style={imageStyle}
              >
                {image && image.images && image.images.fallback && (
                  <img
                    src={image.images.fallback.src}
                    alt=""
                    className="w-full h-full object-cover"
                    aria-hidden="true"
                  />
                )}
              </div>
              <div className="lg:pt-10 lg:pl-20 lg:pr-10 md:px-2.5 md:pt-5 pb-10">
                <div className="w-full relative block md:hidden -mr-2.5">
                  {image && image.images && image.images.fallback && (
                    <img
                      src={image.images.fallback.src}
                      alt={`Portrait of ${teamMember.name}${
                        teamMember.degrees ? `, ${teamMember.degrees}` : ''
                      }, ${teamMember.title || 'Science Team Member'}`}
                      className="w-full h-full object-cover"
                    />
                  )}
                </div>
                {teamMember.biography && (
                  <div
                    className="px-2.5 text-balance"
                    role="region"
                    aria-labelledby="team-member-heading"
                  >
                    <BodyPortableText className="[&_p]:text-base" blocks={teamMember.biography} />
                  </div>
                )}
              </div>
            </div>
          </div>

          <nav
            aria-label="Team member navigation"
            className="grid md:grid-cols-2 grid-cols-1 w-full min-h-80 md:min-h-40 lg:min-h-52"
          >
            {prevSlug ? (
              <TeamMemberButtonNavigation
                asChild
                direction="previous"
                name={prevName || ''}
                title={prevTitle}
              >
                <Link
                  to={`/our-story/science/${prevSlug}`}
                  aria-label={`Previous team member: ${prevName}${prevTitle ? `, ${prevTitle}` : ''}`}
                />
              </TeamMemberButtonNavigation>
            ) : (
              <div className="py-[60px] px-10" aria-hidden="true" />
            )}

            {nextSlug ? (
              <TeamMemberButtonNavigation
                asChild
                direction="next"
                name={nextName || ''}
                title={nextTitle}
              >
                <Link
                  to={`/our-story/science/${nextSlug}`}
                  aria-label={`Next team member: ${nextName}${nextTitle ? `, ${nextTitle}` : ''}`}
                />
              </TeamMemberButtonNavigation>
            ) : (
              <div className="py-[60px] px-10" aria-hidden="true" />
            )}
          </nav>
        </main>
      </Layout>
    </>
  )
}

export const scienceTeamMemberFragment = graphql`
  fragment sanityScienceTeamMember on SanityTeamMemberScience {
    id
    name
    title
    degrees
    teamType
    biography: _rawBiography
    photo {
      asset {
        _id
      }
      hotspot {
        height
        width
        x
        y
      }
      crop {
        bottom
        left
        right
        top
      }
    }
    slug {
      current
    }
  }
`
