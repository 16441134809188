import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { ChevronLeft, MoreHorizontal } from 'lucide-react';
import { cn } from '../lib/utils';
const Breadcrumb = React.forwardRef(({ ...props }, ref) => React.createElement("nav", { ref: ref, "aria-label": "breadcrumb", ...props }));
Breadcrumb.displayName = 'Breadcrumb';
const BreadcrumbList = React.forwardRef(({ className, children, ...props }, ref) => (React.createElement("ol", { ref: ref, className: cn('flex flex-wrap items-center gap-2 break-words text-sm sm:text-base font-medium font-gotham sm:gap-3', className), ...props },
    React.createElement("li", null,
        React.createElement(ChevronLeft, { "aria-label": "breadcrumb left arrow", className: "h-6 w-6 text-brand-navy-2" })),
    children)));
BreadcrumbList.displayName = 'BreadcrumbList';
const BreadcrumbItem = React.forwardRef(({ className, ...props }, ref) => (React.createElement("li", { ref: ref, className: cn('inline-flex items-center gap-1.5', className), ...props })));
BreadcrumbItem.displayName = 'BreadcrumbItem';
const BreadcrumbLink = React.forwardRef(({ asChild, className, ...props }, ref) => {
    const Comp = asChild ? Slot : 'a';
    return React.createElement(Comp, { ref: ref, className: cn('text-brand-navy-2 no-underline', className), ...props });
});
BreadcrumbLink.displayName = 'BreadcrumbLink';
const BreadcrumbPage = React.forwardRef(({ className, ...props }, ref) => (React.createElement("span", { ref: ref, role: "link", "aria-disabled": "true", "aria-current": "page", className: cn('text-brand-navy', className), ...props })));
BreadcrumbPage.displayName = 'BreadcrumbPage';
const BreadcrumbSeparator = ({ children, className, ...props }) => (React.createElement("li", { role: "presentation", "aria-hidden": "true", className: cn('text-brand-navy-2 [&>svg]:w-3.5 [&>svg]:h-6', className), ...props }, children ?? React.createElement("span", { className: "text-base" }, "/")));
BreadcrumbSeparator.displayName = 'BreadcrumbSeparator';
const BreadcrumbEllipsis = ({ className, ...props }) => (React.createElement("span", { role: "presentation", "aria-hidden": "true", className: cn('flex h-9 w-9 items-center justify-center', className), ...props },
    React.createElement(MoreHorizontal, { className: "h-4 w-4" }),
    React.createElement("span", { className: "sr-only" }, "More")));
BreadcrumbEllipsis.displayName = 'BreadcrumbElipssis';
export { Breadcrumb, BreadcrumbList, BreadcrumbItem, BreadcrumbLink, BreadcrumbPage, BreadcrumbSeparator, BreadcrumbEllipsis, };
