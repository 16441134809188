import * as React from 'react';
import { cva } from 'class-variance-authority';
import { Slot } from '@radix-ui/react-slot';
import { ChevronLeft } from './Icons/ChevronLeft';
import { ChevronRight } from './Icons/ChevronRight';
import { cn } from '../lib/utils';
const teamMemberButtonNavigationVariants = cva('rounded-none bg-white flex justify-start border border-solid hover:shadow-sm active:bg-transparent active:shadow-none active:border-brand-navy active:border-2', {
    variants: {
        direction: {
            previous: 'border-r-[#CFCECE] border-b border-b-[#CFCECE] md:border-b-0 hover:border-inherit',
            next: 'hover:border-transparent',
        },
    },
    defaultVariants: {
        direction: 'previous',
    },
});
const TeamMemberButtonNavigation = React.forwardRef(({ className, direction = 'previous', asChild = false, name, title, children, ...props }, ref) => {
    const isPrevious = direction === 'previous';
    const Icon = isPrevious ? ChevronLeft : ChevronRight;
    const contentClassName = `flex items-center !gap-10 p-10 w-full ${isPrevious ? 'justify-start' : 'justify-between'}`;
    const content = (React.createElement("div", { className: contentClassName },
        isPrevious && React.createElement(Icon, { className: "w-5 h-10 text-[#0B2240]" }),
        React.createElement("div", { className: "flex flex-col gap-3.5" },
            React.createElement("div", { className: "text-brand-navy lg:text-[40px] md:text-2xl text-2xl text-wrap leading-none font-semibold" }, name),
            title && (React.createElement("div", { className: "text-brand-navy-2 text-base text-wrap font-normal" }, title))),
        !isPrevious && React.createElement(Icon, { className: "w-5 h-10 text-[#0B2240]" })));
    // When asChild is true, we pass our styles and content to the child element (e.g., a Link)
    if (asChild && React.isValidElement(children)) {
        return (React.createElement(Slot, { className: cn(teamMemberButtonNavigationVariants({ direction, className })), ref: ref, ...props }, React.cloneElement(children, undefined, content)));
    }
    // When asChild is false, we render a button with our content
    return (React.createElement("button", { className: cn(teamMemberButtonNavigationVariants({ direction, className })), ref: ref, ...props }, content));
});
TeamMemberButtonNavigation.displayName = 'TeamMemberButtonNavigation';
export { TeamMemberButtonNavigation, teamMemberButtonNavigationVariants };
